#payment, #refundable {
  table {
    font-size: 12px;
    line-height: 2;
    width: 100%;
    @include desktop_and_tablet {
      font-size: 14px;
    }
  }

  th {
    text-align: left;
    color: rgba(36, 36, 36, 0.49);
    font-weight: normal;
  }

  td {
    padding-right: 8px;
    border-bottom: 1px solid #ddd;
  }

  .unpaid {
    color: red;
    font-style: italic;
  }
}

#payment {
  td {
    &:nth-child(3) {
      padding-right: 0;
    }
    &:last-child {
      padding: 0;
      text-align: center;
      width: 20px;
      cursor: pointer;
      svg {
        width: 16px;
      }
    }
  }
}
